<template>
   <main v-if="produto != null">
      <section class="product__area box-plr-75 pb-70 pt-70">
         <div class="container-fluid">
            <div class="row">
               <div class="col-xxl-5 col-xl-5 col-lg-5">
                  <div class="product__details-nav d-sm-flex align-items-start w-100">
                     <ul class="nav nav-tabs flex-sm-column justify-content-between mr-10" id="productThumbTab" role="tablist" v-if="produto.fotos.length > 0">
                        <li v-for="(foto, index) in produto.fotos" :key="index" class="nav-item" role="presentation">
                           <button class="nav-link border-0 pt-0 pb-2" :class="index == 0 ? 'active' : ''" :id="'thumb'+ index +'-tab'" data-bs-toggle="tab" :data-bs-target="'#thumb'+ index" type="button" role="tab" :aria-controls="'thumb'+ index" :aria-selected="index == 0 ? 'true' : 'false'">
                              <img class="border" :src="foto.foto" alt="Imagem" />
                           </button>
                        </li>
                     </ul>
                     <ul class="nav nav-tabs flex-sm-column justify-content-between mr-10" id="productThumbTab" role="tablist" v-else>
                        <li class="nav-item" role="presentation">
                           <button class="nav-link border-0 active pt-0 pb-2" id="thumbtab" data-bs-toggle="tab" data-bs-target="#thumb" type="button" role="tab" aria-controls="thumb" aria-selected="true">
                              <img class="border" :src="errorOnLoadImg" alt="Imagem" />
                           </button>
                        </li>
                     </ul>

                     <div class="product__details-thumb w-100" v-if="produto.fotos.length > 0">
                        <div class="tab-content" id="productThumbContent">
                           <div v-for="(foto, index) in produto.fotos" :key="index" class="tab-pane fade" :class="index == 0 ? 'show active' : ''" :id="'thumb'+ index" role="tabpanel" :aria-labelledby="'thumb'+ index +'-tab'">
                              <div class="product__details-nav-thumb">
                                 <img class="border w-90" :src="foto.foto" alt="Imagem">
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="product__details-thumb w-100" v-else>
                        <div class="tab-content" id="productThumbContent">
                           <div class="tab-pane fade show active" id="thumb" role="tabpanel" aria-labelledby="thumb-tab">
                              <div class="product__details-nav-thumb">
                                 <img class="border w-100" :src="errorOnLoadImg" alt="Imagem">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-7 col-xl-7 col-lg-7">
                  <div class="product__details-wrapper">
                     <div class="product__details">
                        <h3 class="product__details-title">
                           <a href="javascript:;">{{ produto.nome }}</a>
                        </h3>
                        <div class="product__price">
                           <span class="new">R$ {{ parseFloat(produto.preco).toFixed(2) }}</span>
                        </div>
                        <div class="product__stock">
                           <span>Disponibilidade:</span>
                           <span v-if="produto.estoque >= 1">Em estoque</span>
                           <span v-else>Sem estoque</span>
                        </div>
                        <div class="product__details-des mb-30">
                           <p>{{ produto.descricao }}</p>
                        </div>
                        <div class="product__details-quantity mb-20">
                           <div class="pro-quan-area d-lg-flex align-items-center">
                              <div class="product-quantity mr-20 mb-25" v-if="produto.estoque >= 1">
                                 <div class="cart-plus-minus p-relative">
                                    <input type="text" v-model="produto.quantidade" v-mask="'#####'" placeholder="Quantidade" />
                                 </div>
                              </div>
                              <div class="pro-cart-btn mb-25">
                                 <button class="t-y-btn px-5 rounded m-1" type="button" @click="addCarrinho" v-if="produto.estoque >= 1">
                                    <i class="far fa-plus me-2"></i>Adicionar ao carrinho
                                 </button>
                                 <button class="t-y-btn px-5 rounded m-1" type="button" @click="voltar">
                                    <i class="far fa-undo-alt me-2"></i>Voltar
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import router from '@/router'
import { mapState } from 'vuex'

export default {
	name: 'Produto',
   data: function() {
		return {
			produto: null
		}
	},
   computed: {
		... mapState({
         errorOnLoadImg: state => state.errorOnLoadImg
		})
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      voltar : function () {
         router.back()
      },
      addCarrinho: function () {
         if (parseInt(this.produto.quantidade) >= 1) {
            this.$store.dispatch('addCarrinho', this.produto)
   
            this.$toast.fire({
               icon: 'success',
               title: 'Adicionado ao carrinho!'
            });
   
            this.$store.dispatch('configurarCarrinho')
         }
      }
   },
   created() {
      this.produto = JSON.parse(this.$route.params.produto)
   },
   mounted() {
      window.scrollTo(0, 0)
   }
}

</script>